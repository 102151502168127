import React from 'react';
import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import StyledHeading from '../components/core/typography/Heading';
import 'twin.macro';
import styled from 'styled-components';
import { StyledGeneratedHtmlContainer } from './privacy-e-cookie-policy';

export const Heading = styled(StyledHeading)`
  margin-top: 2rem;
`;

const TermsAndConditions = () => {
  return (
    <div tw="py-16">
      <SEO title="Termini e condizioni" url="/termini-e-condizioni" />
      <Container tw="mb-10">
        <Heading variant="h1" tw="mb-5 md:mb-0">
          Termini e condizioni generali
        </Heading>
        <Heading variant="h4" tw="mb-10">
          relativa al sito internet www.hellougo.com
        </Heading>
      </Container>
      <section tw="mb-16">
        <Container tw="text-paragraph">
          <StyledGeneratedHtmlContainer>
            <p>
              <strong>CONDIZIONI DI UTILIZZO UTENTI</strong>
            </p>
            <p>
              Ugo Pnp S.r.l., con sede legale in 20124 – Milano, Via Panfilo
              Castaldi 29 (di seguito,{' '}
              <strong>
                <em>Società o UGO PNP</em>
              </strong>
              ), ha realizzato la piattaforma denominata “UGO” (nel seguito, la{' '}
              <strong>
                <em>Piattaforma</em>
              </strong>
              ) raggiungibile presso il nome a dominio https://hellougo.com/ ed
              eventuali sottodomini e di cui ha la proprietà esclusiva. Le
              presenti condizioni di utilizzo (di seguito,{' '}
              <strong>
                <em>CU </em>
              </strong>
              o
              <strong>
                <em> Condizioni di Utilizzo</em>
              </strong>
              ) disciplinano i rapporti relativi ai servizi offerti da Ugo a
              favore degli utenti, come sotto definiti, e devono essere
              considerate congiuntamente all'Informativa sulla Privacy e
              l’informativa sui cookie.
            </p>
            <p>
              Ugo PNP fornisce un insieme di servizi, tra cui uno spazio online
              a disposizione degli Utenti, come <em>infra</em> definiti, che
              permette di mettere in comunicazione gli Utenti con UGO privati,
              opportunamente selezionati e formati, disponibili alla
              prestazione, nei confronti degli Utenti, di servizi di
              affiancamento e/o accompagnamento di persone fragili o con ridotta
              mobilità, al fine di aiutarle nello svolgimento di attività
              quotidiane (nel seguito, il{' '}
              <strong>
                <em>Servizio</em>
              </strong>
              ). Utilizzando il Servizio, l'Utente accetta le Condizioni di
              Utilizzo della Piattaforma. Il Servizio reso da UGO PNP e
              l’utilizzo della Piattaforma, come <em>infra</em> definiti, sono
              soggetti all’applicazione delle CU e di ogni altro regolamento o
              policy pubblicata da UGO PNP sulla Piattaforma. In caso di
              disaccordo rispetto a qualsiasi termine o condizione o successive
              modifiche delle stesse, ovvero nel caso di insoddisfazione per i
              Servizi offerti da UGO PNP, l'Utente avrà facoltà e dovere di
              interrompere immediatamente l'uso della Piattaforma. UGO PNP ha la
              facoltà, ma non l’obbligo, di far rispettare rigorosamente le
              Condizioni di Utilizzo attraverso la moderazione della comunità ed
              il contenzioso nelle opportune sedi giudiziarie.
            </p>
            <p>
              L’Utente che intenda accedere alla Piattaforma e fruire del
              Servizio, presta il proprio esplicito consenso all’immediata
              attivazione del Servizio al momento della creazione dell’Account
              Utente e conseguentemente dichiara di rinunciare, così come
              rinuncia, al diritto di recesso nei confronti di tutte le Parti
              coinvolte nella prestazione del Servizio come indicato al
              precedente paragrafo. Per maggiori informazioni si rimanda al
              Paragrafo 5.2 del presente documento.
            </p>
            <p>
              <strong>1. Definizioni</strong>
            </p>
            <p>
              <strong>
                <em>Condizioni di Utilizzo o CU</em>
              </strong>
              : quanto disposto nel presente documento.
            </p>
            <p>
              <strong>
                <em>Contenuti</em>
              </strong>
              :(i) file contenenti testi, immagini, registrazioni audio e/o
              video, dati e/o informazioni organizzati in banca di dati e
              comunque tutti i file, documenti e/o informazioni in qualsiasi
              formato rese dagli Utenti e che UGO PNP rende disponibili
              attraverso la Piattaforma, o i Servizi, incluso qualsiasi
              Contenuto concesso in licenza da una terza parte; (ii) file
              contenenti testi, immagini, registrazioni audio e/o video, dati
              e/o informazioni organizzati in banca dati e comunque tutti i
              file, documenti e/o informazioni in qualsiasi formato che vengono
              trasmessi, copiati, inviati, messi a disposizione degli Utenti
              della Piattaforma anche dagli Utenti stessi.
            </p>
            <p>
              <strong>
                <em>Contributo</em>
              </strong>
              : la somma richiesta da UGO PNP agli Utenti per la fruizione del
              Servizio come determinato nell’Articolo 6 delle presenti CU.
            </p>
            <p>
              <strong>
                <em>Dati Personali</em>
              </strong>
              : tutte le informazioni personali relative a ciascun Utente,
              inclusi il nome della persona, l'indirizzo e numero di telefono,
              eventualmente raccolte tramite la Piattaforma.
            </p>
            <p>
              <strong>
                <em>Piattaforma</em>
              </strong>
              : il dominio https://hellougo.com/, ed ogni suo contenuto, inclusi
              gli eventuali sottodomini, qualsiasi URL o loro porzioni.
            </p>
            <p>
              <strong>
                <em>Servizio</em>
              </strong>
              : Servizi che UGO PNP offre attraverso la Piattaforma consistente
              in uno spazio online a disposizione degli Utenti, come infra
              definiti, che permette di mettere in comunicazione gli Utenti con
              UGO privati disponibili alla prestazione, nei confronti di persone
              fisiche o giuridiche, di servizi di affiancamento e/o
              accompagnamento di persone fragili o con ridotta mobilità al fine
              di aiutarle nello svolgimento di attività quotidiane.
            </p>
            <p>
              <strong>
                <em>Servizio UGO</em>
              </strong>
              : servizi di affiancamento e/o accompagnamento di persone fragili
              o con ridotta mobilità al fine di aiutarle nello svolgimento di
              attività quotidiane. Possono consistere, a mero titolo
              esemplificativo, nel fare una passeggiata, seguire insieme un
              ciclo di terapia, andare alle poste a pagare le bollette, recarsi
              nei negozi del quartiere per la piccola spesa quotidiana, andare
              al parco a leggere un libro, andare a una mostra oppure al cinema
              oppure in centro a fare due passi, ecc.{' '}
            </p>
            <p>
              <strong>
                <em>Progetto Extra</em>
              </strong>
              : progetto ideato, sponsorizzato o gestito in collaborazione o per
              conto di terze parti (partner), che potrebbe includere servizi
              normalmente non presenti sulla Piattaforma e/o servizi con
              modalità e/o a condizioni economiche agevolate.{' '}
            </p>
            <p>
              <strong>
                <em>Utente</em>
              </strong>
              : tutte le persone fisiche o giuridiche che accedono alla
              Piattaforma al fine di richiedere il Servizio.
            </p>
            <p>
              <strong>
                <em>Caregiver</em>
              </strong>
              : l’Utente che prenota il Servizio per conto dell’effettivo
              beneficiario.
            </p>
            <p>
              <strong>
                <em>UGO</em>
              </strong>
              : tutte le persone fisiche, opportunamente selezionate e formate,
              disponibili alla prestazione, nei confronti degli Utenti, di
              servizi di accompagnamento e affiancamento richiesto dall’Utente
              stesso.{' '}
            </p>
            <p>
              <strong>1.2</strong> A seconda del contesto, il significato di un
              termine usato al singolare comprende il plurale, quello di un
              termine usato al maschile comprende il femminile, e viceversa.
            </p>
            <p>
              <strong>2. Descrizione della Piattaforma</strong>
            </p>
            <p>
              <strong>2.1</strong> La Piattaforma e i Servizi comprendono una
              piattaforma online attraverso la quale:{' '}
            </p>
            <p>
              a) gli Utenti possono richiedere un UGO per la prestazione di
              servizi di affiancamento e/o accompagnamento che può svolgersi a
              piedi, con l’utilizzo dei mezzi pubblici, con l’ausilio di un taxi
              oppure tramite veicolo proprio, ovvero messo a disposizione
              dall’UGO; b) gli Utenti possono richiedere che il mezzo di
              trasporto, proprio oppure fornito dall’UGO, sia comprensivo di
              attrezzatura speciale volta a facilitare lo spostamento della
              persona con particolari esigenze e/o necessità. Resta inteso che
              anche in tal caso Ugo non fornisce direttamente il mezzo di
              trasporto dotato di attrezzatura speciale, rivolgendosi a
              fornitori terzi estranei alla Piattaforma; c) l’UGO fornisce
              all’Utente, qualora quest’ultimo lo richieda, informazioni sullo
              svolgimento del Servizio di accompagnamento e il Caregiver viene
              notificato della corretta conclusione del Servizio da parte
              dell’UGO; e) l’UGO e l’Utente, al termine di ogni Servizio,
              possono condividere la loro esperienza e livello di soddisfazione
              e/o gradimento del Servizio al fine di migliorare il Servizio per
              i futuri Utenti; f) l’Utente può effettuare il pagamento del
              Contributo a fronte del Servizio ricevuto.{' '}
            </p>
            <p>
              <strong>3. Privacy</strong>
            </p>
            <p>
              L'Utente dichiara di avere preso visione dell’Informativa sulla
              Privacy di Ugo, disponibile all'indirizzo{' '}
              <a href="https://hellougo.com/privacy-e-cookie-policy/">
                https://hellougo.com/privacy-e-cookie-policy/
              </a>
              , come modificata di volta in volta; tale Informativa disciplina
              l'acquisizione e l'utilizzo da parte di Ugo dei Dati personali
              degli Utenti.
            </p>
            <p>
              In particolare, Ugo dà atto e conferma che ogni trattamento di
              dati personali che derivi dall’esecuzione delle CU verrà
              effettuato unicamente per le finalità connesse a tale esecuzione o
              per le altre finalità per le quali l’Utente abbia conferito
              espresso ed inequivocabile consenso, e comunque nel rispetto di
              quanto previsto dal D.Lgs. 196/2003 e ss.mm., D. Lgs 101/2018
              nonché dal Reg. UE 679/2016.
            </p>
            <p>
              In particolare, l’Utente dichiara di essere stato informato circa:
            </p>
            <p>
              a) le finalità e le modalità del trattamento cui sono destinati i
              dati;
            </p>
            <p>
              b) la natura obbligatoria o facoltativa del conferimento dei dati;
            </p>
            <p>c) le conseguenze di un eventuale rifiuto di rispondere;</p>
            <p>
              d) i soggetti o le categorie di soggetti ai quali i dati possono
              essere comunicati e l’ambito di diffusione dei dati medesimi;
            </p>
            <p>
              e) i diritti di cui agli artt. 15 (diritto di accesso), 16
              (diritto di rettifica), 17 (diritto all’oblio), 18 (diritto di
              limitazione di trattamento), 20 (diritto portabilità dati) del
              Regolamento;
            </p>
            <p>
              f) il nome, la denominazione o la ragione sociale e il domicilio,
              la residenza o la sede del responsabile del trattamento.
            </p>
            <p>
              L’Utente dichiara altresì di essere stato informato che
              l'operatore UGO, nell'ambito dell'adempimento dei propri obblighi
              contrattuali, trattando dati in nome e per conto della UGO pnp
              S.r.l. (titolare del trattamento) viene designato quale
              autorizzato al trattamento e si impegna a rispettare le istruzioni
              impartitegli in relazione ai dati personali e/o particolari dei
              Clienti (soggetti interessati), i quali potranno essere trattati e
              gestiti unicamente per le finalità contrattuali connesse
              all'esecuzione del rapporto instaurato con UGO pnp S.r.l.
            </p>
            <p>
              <strong>4. Account Utente</strong>
            </p>
            <p>
              Per iscriversi e usufruire del servizio, ogni Utente deve
              preventivamente creare un solo account personale, di cui egli sarà
              responsabile per tutte le attività svolte tramite esso, fornendo i
              dati personali che lo riguardano, indispensabili al corretto
              funzionamento del servizio di comunicazione tra persone. A tal
              fine, in caso di variazione dei dati indicati durante la fase di
              registrazione, l’Utente è tenuto ad aggiornare il proprio account
              concordemente alle modifiche avvenute. La creazione dell’account e
              la registrazione dell’Utente si perfezionano con la conferma della
              richiesta da parte dell’Utente.
            </p>
            <p>
              Gli Utenti, con la creazione dell’Account Utente, certificano di
              avere compiuto almeno 18 anni al momento della loro iscrizione.
              Nel caso in cui l’Utente richieda il Servizio per un soggetto
              minore d’età, di cui egli sia genitore o tutore, l’Utente è tenuto
              alla compilazione del Modulo di Autorizzazione di Accompagnamento
              del Figlio Minorenne (nel seguito, il{' '}
              <strong>
                <em>Modulo</em>
              </strong>
              ), che dovrà essere richiesto, compilato e inviato all’indirizzo
              e-mail{' '}
              <a href="mailto:customercare@hellougo.com">
                customercare@hellougo.com
              </a>
              .{' '}
            </p>
            <p>
              Nella compilazione dei form predisposti nella sezione riservata
              all’Utente, questo indicherà una User Id (di cui il sistema
              verificherà in automatico l’univocità) e la password preferita.
              Nel caso in cui la User-Id fosse già presente nel database,
              L’Utente dovrà indicarne una ulteriore fino a quando il sistema
              non verificherà positivamente l’univocità della stessa. La User-Id
              unitamente alla password, costituiranno le Credenziali di
              autenticazione.{' '}
            </p>
            <p>
              La User-Id consentirà l’identificazione dell’Utente, quale
              soggetto a cui riferire l’attività o i servizi richiesti sulla
              Piattaforma.
            </p>
            <p>
              Le Credenziali di autenticazione sono personali, riservate e non
              cedibili.
            </p>
            <p>
              L’Utente si impegna a non fornire alcuna informazione errata o
              falsa. In ogni caso, Ugo non potrà in alcun caso essere ritenuta
              responsabile dell’erroneità o della falsità, incolpevole,
              colpevole o dolosa, delle informazioni comunicate dagli Utenti.{' '}
            </p>
            <p>
              Ogni Utente si impegna ad utilizzare solo ed unicamente l’Account
              inizialmente creato, indipendentemente dal fatto che sia stato
              registrato sotto la propria identità o sotto quella di terzi e
              indipendentemente dalla tipologia di Servizio richiesto. In nessun
              caso l’Utente potrà utilizzare l’Account di terzi. Qualsiasi
              deroga a questa regola dovrà essere richiesta esplicitamente da
              parte dell’Utente a Ugo. L’Utente potrà quindi procedere solo a
              fronte di un'autorizzazione esplicita e specifica di Ugo. In caso
              di mancanza di tale esplicita autorizzazione, la creazione o
              l'utilizzo di nuovi account sotto la propria identità o sotto
              quella di terzi potrà comportare la Sospensione immediata ed a
              tempo indeterminato degli account dell’Utente ovvero la loro
              definitiva cancellazione.
            </p>
            <p>
              Ciascun Utente ha la facoltà di procedere alla cancellazione del
              proprio Account personale inviandone richiesta tramite e-mail
              all’indirizzo{' '}
              <a href="mailto:customercare@hellougo.com">
                customercare@hellougo.com
              </a>
              . La definitiva cancellazione dell’account avverrà a seguito di
              conferma da parte di Ugo di avvenuta cancellazione.
            </p>
            <p>
              <strong>4.1 Cancellazione, Sospensione e Ban dell’Account</strong>
            </p>
            <p>
              L’Utente è tenuto al rispetto delle presenti CU, alle Buone Norme
              e qualunque altro obbligo derivante dalla normativa vigente, oltre
              alle più semplici e basilari norme di convivenza e condivisione
              sociale. In caso di violazione da parte dell’Utente di una o più
              di queste previsioni, UGO PNP si riserva, a sua discrezione, di
              procedere alla Cancellazione, Sospensione e/o Ban, come infra
              definite) dell’Account del suddetto Utente. Resta inteso che le
              violazioni potranno essere apprese anche mediante una o più
              segnalazioni inoltrate dagli altri Utenti o dagli UGO.{' '}
            </p>
            <p>
              Cancellazione Account: L’Account del singolo Utente viene
              completamente rimosso dalla Piattaforma e ogni dato riguardante
              l’Utente viene cancellato. Pertanto, in seguito a cancellazione,
              UGO PNP non sarà più in possesso di alcun dato comunicato
              dall’Utente in fase di registrazione e creazione dell’Account.
            </p>
            <p>
              Sospensione Account: l’Utente mantiene il proprio Account
              personale oltre a poter accedere alla Piattaforma. Tuttavia,
              all’Utente sarà impedito di utilizzare i Servizi offerti sulla
              Piattaforma.
            </p>
            <p>
              Ban Account: l’Account Utente verrà completamente bloccato in ogni
              sua funzionalità senza, tuttavia, venire rimosso e/o cancellato.
              In questo modo UGO PNP potrà impedire che l’Utente sottoposto a
              Ban crei un nuovo Account Utente riutilizzando le proprie
              credenziali e proceda ad utilizzare nuovamente i Servizi. Resta
              inteso che UGO PNP utilizzerà i dati e le informazioni personali
              dell’Utente sottoposto a Ban solo ed esclusivamente nella misura
              strettamente necessaria a rendere efficace il Ban e a monitorare
              (e impedire) eventuali tentativi di nuova apertura di Account
              Utente da parte del soggetto sottoposto a Ban.
            </p>
            <p>
              <strong>5. Prenotazione</strong>
            </p>
            <p>
              Sulla Piattaforma sono rese disponibili le informazioni relative
              al servizio fornito dagli UGO, nelle quali sono descritte
              dettagliatamente le caratteristiche e i servizi accessori resi
              disponibili come, a titolo esemplificativo e non esaustivo, la
              possibilità di: a) richiedere un UGO specificando altresì se si
              necessita di un veicolo dotato di attrezzatura particolare per il
              trasporto di persone a mobilità ridotta messo a disposizione da un
              fornitore terzo. Resta inteso che Ugo non si occupa della
              esclusiva fornitura di mezzi di trasporto con attrezzatura
              speciale; b) concordare con l’UGO data, ora, luogo e modalità di
              trasporto in base alle necessità dell’Utente; c) se il Servizio
              UGO è da erogarsi a piedi oppure con i mezzi pubblici oppure con
              la vettura dell’utente o con quella dell’operatore UGO; d) se si
              prevede una singola tratta oppure andata e ritorno; e) se il
              Servizio UGO viene prenotato per sé stessi oppure per un proprio
              caro; f) altre note di dettaglio che l’utente voglia fornire utili
              all’erogazione di un Servizio UGO o parte di esso.{' '}
            </p>
            <p>
              Le modalità di fruizione del Servizio vengono concordate tra Ugo e
              l’Utente previa prenotazione da effettuarsi almeno ventiquattro
              (24) ore precedentemente la fornitura del Servizio. I servizi
              last-minute sotto le 24 ore di preavviso possono essere richiesti
              e verranno gestiti dal servizio clienti: non si assicura però in
              merito a questi, esito positivo di organizzazione ed erogazione.{' '}
            </p>
            <p>
              <strong>5.1</strong> Cancellazione della prenotazione e costi
              aggiuntivi.
            </p>
            <p>
              L’utente si impegna a gestire i propri servizi nel rispetto dei
              tempi e delle modalità concordate. Ogni comportamento non conforme
              ai tempi e modalità della piattaforma, può comportare
              l’applicazione di somme aggiuntive e/o extra costi a carico degli
              utenti, come di seguito descritte.
            </p>
            <p>
              E’ sempre possibile annullare una prenotazione. Nel caso in cui la
              cancellazione del servizio avvenga con meno di 24 ore di anticipo
              rispetto all’orario di inizio dello stesso, la società UGO pnp si
              riserva il diritto di addebitare il 50% del prezzo del servizio.
            </p>
            <p>
              Nel caso in cui la cancellazione del servizio avvenga con meno di
              6 ore di preavviso o in mancata presenza dell’Utente all’orario e
              nel luogo concordati per il servizio, la società UGO pnp si
              riserva il diritto di addebitare il 100% del prezzo del servizio.
            </p>
            <p>
              <strong>5.2</strong> Recesso
            </p>
            <p>
              In conformità alla Direttiva 2011/83/UE e all’art. 52 del D. Lgs.
              2005, n. 206 (nel seguito,{' '}
              <strong>
                <em>Codice del Consumo</em>
              </strong>
              ) l’Utente ha il diritto di recedere entro 14 giorni dal momento
              della 1) richiesta del Servizio, 2) prenotazione dell’Ordine e 3)
              prenotazione di un servizio relativo al Progetto Extra. Con
              riguardo ai suddetti servizi, tuttavia, l’Utente, al momento
              dell’iscrizione della Piattaforma, accetta esplicitamente di
              rinunciare a tale diritto per le seguenti ragioni:
            </p>
            <ol>
              <li>
                <strong>Servizio offerto dalla Piattaforma</strong>: il Servizio
                consiste in un contenuto digitale offerto mediante un supporto
                non materiale che ha luogo immediatamente dal momento
                dell’iscrizione dell’Utente alla Piattaforma. Tale circostanza
                costituisce una ipotesi di esclusione di applicazione del
                diritto di recesso così come previsto ai sensi dell’art. 59, co
                1 lett. o) del Codice del Consumo.
              </li>
              <li>
                <strong>Prenotazione dell’Ordine</strong>: il servizio offerto
                riguarda attività del tempo libero e il contratto prevede una
                data o un periodo di esecuzione specifici. Tale circostanza
                costituisce una ipotesi di esclusione di applicazione del
                diritto di recesso così come previsto ai sensi dell’art. 59, co
                1 lett. n) del Codice del Consumo.
              </li>
            </ol>
            <p>
              <strong>6. Pagamenti</strong>
            </p>
            <p>
              6.1 Ugo mette a disposizione dell’Utente e degli UGO un servizio
              di terze parti, mediante il quale l’Utente che intende usufruire
              del Servizio può procedere al pagamento del Contributo. Ugo,
              pertanto, non agisce né opera in nessun caso in qualità di
              venditore ufficiale, né di fornitore di servizi di pagamento,
              limitandosi a fornire l’accesso alla Piattaforma ed al suo
              mantenimento. Il pagamento mediante la Piattaforma costituirà in
              ogni caso un pagamento, totale o parziale, da parte dell’Utente
              del Contributo per la fruizione del Servizio e non potrà essere
              reclamato in alcun modo dall’Utente, fatto salvo quanto stabilito
              per il Progetto Extra nel Paragrafo 6 delle C.U.{' '}
            </p>
            <p>
              6.2 Per il pagamento, da effettuarsi mediante carta di credito o
              di debito, l’Utente potrà fruire del servizio offerto da terze
              parti (nel seguito, il{' '}
              <strong>
                <em>Sistema di Pagamento</em>
              </strong>
              ), tramite la Piattaforma. Ugo mette a disposizione sulla propria
              Piattaforma un sistema di elaborazione di pagamento esterno che
              consente all’Utente di collegare la propria carta di credito o
              carta prepagata al fine di effettuare pagamenti a fronte del
              Servizio ricevuto. L’elaborazione dei pagamenti è soggetta ai
              termini e condizioni applicate dal Sistema di Pagamento che può
              essere consultato qui
              <a href="https://stripe.com/it/ssa.">
                {' '}
                <em>https://stripe.com/it/ssa</em>.{' '}
              </a>
            </p>
            <p>
              6.3 Ugo stabilisce delle tariffe minime calcolate su base oraria
              del Servizio UGO (di seguito,{' '}
              <strong>
                <em>Tariffa Base)</em>
              </strong>{' '}
              che sono indicate sulla Piattaforma all’indirizzo{' '}
              <a href="https://hellougo.com/prezzi-accompagnamento/">
                https://hellougo.com/prezzi-accompagnamento/
              </a>{' '}
              e, più in generale, nella sezione dedicata ai prezzi e tariffe
              sulla Piattaforma. E’ inoltre possibile effettuare un calcolo
              prognostico del preventivo al link{' '}
              <a href="https://app.hellougo.com/quote/service">
                https://app.hellougo.com/quote/service
              </a>
              . Il Contributo risulterà pertanto costituito dall’applicazione
              della Tariffa Base preventivata e potrà essere oggetto di
              modifica, al termine del Servizio, in ragione delle modalità di
              svolgimento di quest’ultimo prendendo in considerazione, a titolo
              esemplificativo ma non esaustivo:
            </p>
            <ul>
              <li>
                esborsi effettuati per il pagamento di carburante, pedaggi,
                soste, parcheggi qualora l’Utente utilizzi una autovettura messa
                a disposizione dall’UGO e che saranno pertanto dovuti a titolo
                di rimborso spese (di seguito, il{' '}
                <strong>
                  <em>Rimborso</em>
                </strong>
                );
              </li>
              <li>
                la richiesta dell’Utente di attrezzatura speciale e/o vetture
                attrezzate per l’ausilio alla deambulazione;
              </li>
              <li>
                Una durata complessiva superiore a quella stimata a preventivo
              </li>
              <li>
                Una tratta chilometrica complessiva diversa da quella stimata a
                preventivo.
              </li>
            </ul>
            <p>
              Qualora l’Utente richieda il supporto di UGO PNP per la gestione
              della Prenotazione e cioè nel caso in cui l’Utente non ritenga o
              non possa procedere mediante la Piattaforma, UGO PNP potrà
              applicare un costo aggiuntivo di Euro 5,00 (oltre oneri di legge).
            </p>
            <p>
              6.4 Il Contributo è sempre comunicato all’Utente con un preventivo
              e un consuntivo:
            </p>
            <ul>
              <li>
                il preventivo riporta il Contributo calcolato al momento della
                prenotazione del Servizio in base alle richieste effettuate
                dall’Utente;
              </li>
              <li>
                il consuntivo riporta il Contributo effettivamente dovuto
                dall’Utente come aggiornato in base alle modifiche apportati ai
                sensi del Paragrafo 6.3 (di seguito, il{' '}
                <strong>
                  <em>Contributo Finale</em>
                </strong>
                ).
              </li>
            </ul>
            <p>
              Ugo si riserva la facoltà di apportare modifiche, anche
              periodiche, all’ammontare delle Tariffe Base senza alcun obbligo
              di notifica agli Utenti. Pertanto, è onere dell’Utente accertarsi
              delle corrette tariffe al momento della richiesta del Servizio.{' '}
            </p>
            <p>
              Qualora l’Utente adoperi una propria vettura per la fruizione del
              Servizio di Accompagnamento, il Contributo sarà comprensivo di una
              copertura assicurativa fornita dalla compagnia Zurich - Zurich
              House, Ballsbridge Park, Dublino 4, Irlanda - il cui costo sarà
              addebitato automaticamente all’Utente.{' '}
            </p>
            <p>
              L’Utente corrisponde all’UGO il Contributo Finale di cui solo una
              percentuale viene trattenuta sulla Piattaforma di Ugo. Pertanto,
              Ugo non è responsabile per il trasferimento di denaro tra l’Utente
              e l’UGO, così come non è responsabile della fatturazione delle
              somme pagate dall’Utente all’UGO quale Contributo Finale. L’invio
              della documentazione contabile e/o fiscale, laddove necessaria,
              sarà eseguito direttamente dal soggetto di volta in volta a ciò
              tenuto ai sensi della normativa applicabile. Ugo non è in alcun
              modo tenuto alla verifica della sussistenza dei requisiti, delle
              condizioni e dei termini entro i quali sia necessario provvedere
              all’emissione di documentazione contabile e/o fiscale, di ogni
              tipo e genere, nei confronti e/o da parte degli UGO.
            </p>
            <p>
              Al fine della produzione e dell’inoltro della documentazione
              fiscale e contabile (ivi incluse quietanze, ricevute, fatture ed
              ogni altro documento equipollente, comunque denominato) da parte
              del soggetto a ciò tenuto, saranno utilizzati solo ed
              esclusivamente i dati forniti dall’Utente al momento della
              registrazione o comunque quelli disponibili nella anagrafica
              dell’Utente sulla Piattaforma. L’Utente si impegna a fornire
              informazioni corrette, valide ed aggiornate e garantisce di non
              aver fornito e di non fornire alcuna informazione errata o falsa.
              In ogni caso, Ugo non potrà in alcun caso essere ritenuto
              responsabile dell’erroneità o della falsità, incolpevole,
              colpevole o dolosa, delle informazioni comunicate dall’Utente.
              Quest’ultimo si impegna a manlevare e tenere indenne Ugo da ogni
              danno e/o responsabilità derivanti, direttamente o indirettamente,
              dall’utilizzo di dati forniti e rivelatisi errati o falsi.
            </p>
            <p>
              <a id="_heading=h.gjdgxs"></a>
              <strong>7. Tasse</strong>
            </p>
            <p>
              Il Contributo, il Contributo Finale e tutte le sue eventuali
              variazioni sono comprensive di IVA, se applicabile, e di ogni
              altra tassa applicabile.
            </p>
            <p>
              Ugo non paga tasse per conto degli UGO o degli Utenti. Gli UGO e
              gli Utenti sono gli unici responsabili di eventuali Imposte ad
              essi relative e derivanti da qualsiasi Contratto intercorso.
            </p>
            <p>
              <strong>8. Obblighi dell’Utente</strong>
            </p>
            <p>L’Utente accetta, dichiara e si impegna a:</p>
            <p>
              i) utilizzare la Piattaforma e l’Account in maniera esclusivamente
              personale, ovvero in nome e per conto di un soggetto minore d’età
              secondo le modalità stabilite all’articolo 4, e di non cedere a
              nessun titolo l’Account personale;
            </p>
            <p>
              ii) non impiegare la Piattaforma, il Servizio e l’account
              personale al fine di arrecare disagi, disturbo o inconvenienti a
              terzi, ovvero compiere atti illeciti e/o fraudolenti come, a
              titolo esemplificativo e non esaustivo: arrecare danni alla
              Piattaforma, inviare messaggi di spam o altre comunicazioni
              indesiderate, diffondere materiale contenente virus, worm, trojan
              o altri codici, file, script, agenti o programmi dannosi per il
              gli altri utenti o terzi;
            </p>
            <p>
              iii) non intervenire e/o effettuare modifiche al software della
              Piattaforma, decompilare, disassemblare, modificare e/o creare
              lavori derivati dalla Piattaforma o una parte di essa; non
              copiare, conservare, modificare, preparare e/o distribuire lavori
              derivati o alterare in qualunque modo i contenuti forniti dalla
              Piattaforma; non utilizzare alcun tipo di robot, spider,
              piattaforma di ricerca/reperimento di siti, o qualunque altro
              dispositivo, processo o mezzo automatico per accedere, recuperare,
              effettuare scraping o indicizzare qualunque porzione della
              Piattaforma e/o dei suoi contenuti;
            </p>
            <p>
              iv) mantenere una condotta adeguata e conforme alla natura del
              Servizio senza arrecare danno e/o porre in situazione di pericolo
              l’Utente stesso e l’UGO;
            </p>
            <p>
              v) non fare uso, trasportare con mezzo proprio o dell’UGO,
              consegnare e/o svolgere ogni altra attività legata all’impiego,
              uso, commercio e/o distribuzione di sostanze stupefacenti.{' '}
            </p>
            <p>
              Ugo si riserva il diritto di effettuare controlli al fine di
              verificare che l’Utente non ponga in essere alcuna delle condotte
              indicate al presente articolo o contraria alla normativa in
              vigore. Qualora Ugo riscontri che la condotta di un utente sia in
              violazione con il presente articolo, Ugo ha la facoltà, in
              qualsiasi momento e senza darne preavviso, di rimuovere l’account
              dell’Utente e/o impedirne l'accesso alla Piattaforma.
            </p>
            <p>
              Qualora l’Utente non rispetti gli obblighi elencati nel presente
              articolo quest’ultimo manleva Ugo e l’UGO da ogni responsabilità
              per danni arrecati a cose e/o terzi derivanti dall’impiego del
              veicolo dell’Utente.
            </p>
            <p>
              <strong>8.1 </strong>obblighi dell’Utente che utilizza veicolo
              proprio
            </p>
            <p>
              L’Utente che utilizzi veicolo proprio durante lo svolgimento del
              Servizio accetta, dichiara e si impegna a quanto segue:
            </p>
            <p>
              i) fornire i dati del proprio veicolo affinché la compagnia
              assicurativa dell’Utente sia responsabile in solido con la
              compagnia assicurativa di Ugo in caso di danno a cose e/o terzi
              arrecati dall’UGO durante lo svolgimento del Servizio;
            </p>
            <p>
              ii) garantisce che l’autoveicolo che affiderà all’UGO è di
              proprietà dell’Utente e/o nella propria disponibilità per averne
              ricevuto il possesso e/o facoltà di utilizzo, senza limitazione
              alcuna, dal legittimo proprietario e che la circolazione del
              veicolo non è in contrasto con la volontà di quest’ultimo;
            </p>
            <p>
              iii) dichiara che l’autoveicolo è dotato di apposita polizza
              assicurativa RCA valida, nonché di tutti i dispositivi e requisiti
              previsti dalla normativa tempo per tempo vigente, e pertanto
              manleva l’UGO, per tutta la durata del Servizio, da ogni
              responsabilità derivante dal mancato adempimento di tali obblighi;{' '}
            </p>
            <p>
              iv) il contratto assicurativo del veicolo dell’Utente non prevede
              clausole di esclusività della guida e, in caso contrario, l’Utente
              ha l’obbligo di esibire il permesso temporaneo di concessione
              della guida a terzi da inviare tramite e-mail all’indirizzo{' '}
              <a href="mailto:customercare@hellougo.com">
                customercare@hellougo.com
              </a>
              , previa conferma da parte della società assicuratrice di validità
              della delega.
            </p>
            <p>
              v) dichiara che l’autoveicolo è in buono stato di manutenzione e
              funzionamento e la circolazione dello stesso non costituisce né
              può costituire pericolo per l’incolumità propria e/o degli
              eventuali altri passeggeri, degli UGO né di terzi.
            </p>
            <p>
              vi) dichiara che l’autoveicolo è stato revisionato, è in regola
              con le disposizioni di legge concernenti la revisione periodica
              delle automobili e non è oggetto di provvedimenti limitativi della
              sua circolazione, quale, a mero titolo di esempio e senza pretesa
              di esaustività, fermo amministrativo e simili.
            </p>
            <p>
              vii) La responsabilità per ogni eventuale sanzione amministrativa
              di carattere pecuniario che dovesse essere comminata in relazione
              alle modalità di impiego del veicolo in violazione al codice della
              strada è normalmente imputata all’Ugo, salvo il caso in cui sia
              dimostrato che la responsabilità sia da attribuire all’Utente
            </p>
            <p>
              <a id="_heading=h.1fob9te"></a>
              <strong>8.2</strong> Obblighi COVID19
            </p>
            <p>
              L’Utente si impegna nella fruizione del Servizio UGO a rispettare
              tutte le norme di legge nonché ogni altra regola tecnica tempo per
              tempo vigente in materia di lotta e prevenzione del COVID19,
              nonché ad uniformarsi alle regole di buon senso e ad utilizzare la
              diligenza necessaria.
            </p>
            <p>
              L’Utente, inoltre, dichiara per sé o per il soggetto al quale sarà
              prestato il Servizio UGO, che:
            </p>
            <ul>
              <li>
                non ha avuto nelle ultime due settimane sintomi di infezione
                alle vie respiratorie (tosse, raffreddore o difficoltà
                respiratorie), temperatura corporea (febbre) superiore a 37,5
                °C, o altri sintomi riconducibili all’infezione da Covid 19
                (dolori muscolari, diarrea, alterazioni di gusto e olfatto);
              </li>
              <li>non manifesta attualmente i sintomi di cui sopra</li>
              <li>
                non è a conoscenza di aver avuto nelle ultime due settimane (14
                giorni) contatti con soggetti risultati positivi al Covid19;
              </li>
              <li>
                non è sottoposto a regime di isolamento domiciliare
                (quarantena);
              </li>
              <li>
                di comprendere che le circostanze di cui sopra sono condizioni
                essenziali ai fini della fruizione del Servizio UGO.
              </li>
            </ul>
            <p>
              L’Utente dichiara ed accetta che, laddove anche solo una delle
              suddette dichiarazioni si dovesse rivelare falsa, ciò potrà
              comportare la cancellazione del Servizio UGO con esclusione del
              diritto al rimborso e che sarà ritenuto il solo ed unico
              responsabile per ogni danno o pregiudizio che dovesse derivarne a
              UGO PNP, all’Ugo, agli altri Utenti o ad ogni altro soggetto con
              cui sia venuto a contatto durante la fruizione del Servizio UGO.
            </p>
            <p>
              L’Utente si impegna a informare tempestivamente UGO PNP qualora
              nei 14 giorni successivi alla fruizione del Servizio UGO si
              manifestino sintomi riconducibili al contagio (febbre, tosse,
              raffreddore, sintomi influenzali, difficoltà respiratorie ecc.) in
              modo da permettere ad UGO PNP di prendere i dovuti e necessari
              provvedimenti (ivi incluso avvisare ogni altro soggetto coinvolto
              nella fruizione del Servizio UGO da parte sua).
            </p>
            <p>
              <strong>8.3</strong> Esibizione del Green Pass (Certificazione
              verde COVID-19 – Reg. UE 952/2021; D.L. 22 aprile 2021 n. 52, ss.
              mm. ii.)
            </p>
            <p>
              In nessun caso UGO PNP, sia al momento della creazione
              dell’Account Utente sia al momento della Prenotazione per la
              fruizione di qualsiasi Servizio UGO od ogni altro Servizio offerto
              dalla Piattaforma, richiede all’Utente l’esibizione del Green
              Pass. Resta inteso che l’Utente, accettando le presenti CU,
              dichiara e garantisce di conoscere, approvare e rispettare ogni
              normativa tempo per tempo vigente, ivi incluso quanto disposto dai
              provvedimenti in materia di lotta e prevenzione del COVID 19.
            </p>
            <p>
              <a id="_Hlk93653615"></a>L’Utente prende atto del fatto che
              l’Operatore UGO che accetta di prestare il Servizio UGO ha la
              facoltà di chiedere all’Utente beneficiario, anche nell’eventuale
              telefonata che precede l’erogazione del Servizio UGO, se
              quest’ultimo sia munito di Green Pass: in caso di risposta
              negativa nonché in caso di rifiuto di fornire risposta, è facoltà
              dell’Operatore UGO fare richiesta di essere sostituito da un altro
              Operatore UGO. In tali casi, il rifiuto e/o la mancata prestazione
              del Servizio UGO da parte dell’Operatore UGO non costituisce
              inadempimento imputabile a UGO PNP né all’Operatore UGO stesso e,
              concordemente, l’Utente rinuncia ad ogni pretesa o contestazione.{' '}
            </p>
            <p>
              <a id="_Hlk93654296"></a>Al momento dell’incontro con l’Utente
              beneficiario, l’Operatore UGO ha la facoltà di controllare il
              Green Pass dello stesso, attraverso l’utilizzo dell’app
              verificaC19, preliminarmente all’erogazione del Servizio UGO
              oggetto di Prenotazione: qualora il beneficiario non esibisca il
              Green Pass o esibisca un Green Pass risultato non valido e/o
              scaduto in seguito alla suddetta verifica, l’Operatore UGO può
              rifiutarsi di fornire il Servizio UGO senza che tale circostanza
              possa in alcun modo costituire inadempimento da parte
              dell’Operatore UGO e/o di UGO PNP. Resta inteso che, in nessun
              caso e per nessun fine, l’Operatore UGO ha la facoltà di chiedere
              all’Utente la consegna finalizzata alla conservazione (anche se
              solo temporanea) del Green Pass.
            </p>
            <p>
              <strong>9. Responsabilità di Ugo</strong>
            </p>
            <p>
              <strong>9.1</strong> Esclusione di garanzie
            </p>
            <p>
              L'Utente accetta che l'uso della Piattaforma e del Servizio da
              esso offerto è interamente a proprio rischio e responsabilità. La
              Piattaforma e il Servizio da essa erogato sono forniti "come sono"
              e "come disponibili", senza garanzie e/o responsabilità di alcun
              tipo. Tutte le garanzie esplicite ed implicite, incluse, senza
              limitazioni, le garanzie di commerciabilità e idoneità per uno
              scopo particolare sono espressamente escluse nella misura massima
              consentita dalla legge, e nella stessa misura, Ugo declina ogni
              garanzia per la sicurezza, l'affidabilità, la tempestività, la
              precisione e le prestazioni della Piattaforma.
            </p>
            <p>
              Nella misura massima consentita dalla legge, Ugo declina ogni
              garanzia per altri servizi o beni ricevuti tramite o pubblicizzati
              sulla Piattaforma.
            </p>
            <p>
              Nella misura massima consentita dalla legge, Ugo declina ogni
              responsabilità in merito alla trasmissione di virus informatici o
              altri componenti dannosi in relazione al Piattaforma o al
              Servizio.
            </p>
            <p>
              <strong>9.2</strong> Limitazioni di responsabilità
            </p>
            <p>
              In nessun caso Ugo potrà essere ritenuto responsabile per danni a
              terzi o alle parti contraenti risultanti, direttamente o
              indirettamente, dall'uso della Piattaforma o del Servizio, o
              risultanti da qualsiasi contratto intervenuto tra l’Utente e
              terzi. Tale esclusione di responsabilità riguarda, tra gli altri,
              danni diretti, indiretti, accidentali, speciali, esemplari e
              consequenziali, inclusi: perdita di profitti, perdita di dati o
              perdita di avviamento; danni informatici; costo di prodotti o
              servizi sostitutivi; qualunque danno connesso a lesioni personali
              o fisiche.
            </p>
            <p>
              Tale limitazione di responsabilità si intende estesa anche ai casi
              in cui Ugo sia informata della possibilità di tali danni.
            </p>
            <p>
              I danni cui la limitazione di responsabilità si riferisce possono
              derivare: dall'uso o abuso della Piattaforma o del Servizio, dalla
              impossibilità di utilizzare la Piattaforma o il Servizio o da
              interruzione, sospensione, modifica, alterazione, o cessazione
              della Piattaforma o del Servizio.
            </p>
            <p>
              Tale limitazione si applica anche in relazione ai danni subiti a
              causa di altri servizi o prodotti ricevuti attraverso pubblicità o
              in connessione con la Piattaforma o il Servizio o qualsiasi link
              sulla Piattaforma, nonché a causa di informazioni o consigli
              ricevuti tramite o pubblicizzati in connessione con la Piattaforma
              o il Servizio o qualsiasi link sulla Piattaforma. Tali limitazioni
              si applicano nella misura massima consentita dalla legge.
            </p>
            <p>
              <strong>9. Sicurezza e feedback degli Utenti</strong>
            </p>
            <p>
              Ugo ha a cuore la sicurezza dei propri Utenti ed il buon esito dei
              propri Servizi nonché delle transazioni poste in essere mediante
              la Piattaforma e pone in essere ogni sforzo finalizzato al miglior
              grado di trasparenza possibile. Tuttavia, Ugo non è tenuta a
              verificare l'esatta identità dei propri Utenti e UGO né può
              confermare l'identità di ciascun Utente e UGO.
            </p>
            <p>
              Allo stesso modo, Ugo non è tenuto alla verifica, né preventiva né
              posteriore, dei commenti o feedback (nel seguito, i{' '}
              <strong>
                <em>Feedback</em>
              </strong>
              ) rilasciati dagli Utenti e UGO relativamente ad un Servizio.
              Qualora un Utente o UGO ritenga che i Feedback possano essere
              offensivi per sé stesso è pregato di darne immediata comunicazione
              a Ugo scrivendo a customercare@hellougo.com. Ugo verificherà
              l’offensività del Feedback segnalato, ponderando l’opportunità di
              eventuali provvedimenti che potranno includere, a seconda dei casi
              e ad esclusiva discrezione di Ugo, la sospensione e/o la
              cancellazione dell’Account dell’Utente e/o UGO autore del Feedback
              segnalato.{' '}
            </p>
            <p>
              <strong>
                10. Contenuti pubblicati su o tramite la Piattaforma
              </strong>
            </p>
            <p>
              <strong>10.1</strong> Contenuti Provenienti dagli Utenti
            </p>
            <p>
              Tutti i Contenuti pubblicati sulla Piattaforma, trasmessi
              attraverso, o accessibili tramite link dalla Piattaforma, sono di
              esclusiva responsabilità dell'Utente da cui tali Contenuti
              provengono. L'Utente è il solo responsabile per qualsiasi
              Contenuto pubblicato, e-mail o materiale altrimenti reso
              disponibile tramite la Piattaforma. L'Utente è consapevole che Ugo
              non controlla e non è responsabile per i Contenuti messi a
              disposizione attraverso la Piattaforma, e che utilizzando la
              Piattaforma, l'Utente può essere esposto a Contenuti offensivi,
              indecenti, imprecisi, illegali, fuorvianti.
            </p>
            <p>
              <strong>10.2 </strong>Link ad altri siti web
            </p>
            <p>
              La Piattaforma, e i Servizi potrebbero contenere link a siti web o
              risorse di terze parti. Ugo non è responsabile per: (i) la
              disponibilità o l'accuratezza di tali siti web o risorse; o (ii) i
              contenuti, i prodotti, o i servizi su o disponibili da tali siti
              web o risorse. I link a tali siti web o risorse non implicano
              alcun sostegno da parte di Ugo a detti siti web o risorse o ai
              Contenuti, prodotti o servizi disponibili da tali siti web o
              risorse. L'Utente si assume l'esclusiva responsabilità e si assume
              tutti i rischi che derivano dal proprio uso di tali siti web o
              risorse o dai Contenuti, prodotti o Servizi su o disponibili da
              tali siti web o risorse.
            </p>
            <p>
              Ugo non è inoltre responsabile per le politiche di riservatezza o
              pratiche adottate su altri siti web. Quando un Utente della
              Piattaforma clicca su un collegamento che indirizza ad un altro
              sito o app, l’Utente è responsabile per la lettura delle politiche
              di riservatezza di detto sito o app.
            </p>
            <p>
              <strong>10.3</strong> Autorizzazione dell'Utente
            </p>
            <p>
              Fermo quanto sopra, gli Utenti e UGO autorizzano Ugo ad utilizzare
              ogni immagine fotografica e/o ripresa video che li ritragga
              durante lo svolgimento del Servizio, salva la facoltà di revocare
              tale autorizzazione mediante comunicazione scritta. Gli Utenti
              autorizzano altresì irrevocabilmente Ugo all’utilizzo di tutti i
              Contenuti ed ogni loro parte od elemento, senza restrizione
              alcuna, in tutto il mondo, in perpetuo e comunque per tutta la
              durata della protezione legale quale sancita in ogni paese del
              mondo.
            </p>
            <p>
              A titolo esemplificativo ma non esaustivo, nella suddetta
              autorizzazione s'intende anche ricompreso il diritto di:
            </p>
            <ul>
              <li>
                pubblicare, diffondere, comunicare al pubblico e mettere a
                disposizione del pubblico in modo che ciascuno possa avervi
                accesso nel momento e nel luogo scelto individualmente, in tutto
                od anche solo in parte, con ogni mezzo e/o tecnologia e con
                qualsiasi modalità (anche interattiva), attualmente noti e/o
                sviluppati in futuro, i Contenuti attraverso la Piattaforma;
              </li>
              <li>
                ai fini di quanto sopra, riprodurre e modificare i Contenuti in
                ogni modo o forma per rispettare i vincoli tecnologici imposti
                dalle modalità di funzionamento della Piattaforma;
              </li>
              <li>
                autorizzare gli altri Utenti della Piattaforma a riprodurre i
                Contenuti al fine di visualizzarli sui loro terminali
                utilizzando i Servizi e la Piattaforma.
              </li>
            </ul>
            <p>
              I diritti di cui sopra si intendono concessi in via non esclusiva
              e pertanto l'Utente resta legittimato ad utilizzare gli stessi
              Contenuti in ogni forma e modo.
            </p>
            <p>
              <strong>10.4</strong> Dichiarazione dell'Utente
            </p>
            <p>
              L’Utente dichiara di disporre pienamente e legittimamente di tutti
              i diritti relativi ai Contenuti (a titolo esemplificativo ma non
              esaustivo: diritti d'autore, diritti di immagine e/o della
              personalità in genere, diritti di marchio ed altri diritti di
              privativa, diritti di tutela della riservatezza, diritti relativi
              al ritratto ecc.) che procede a pubblicare sulla Piattaforma.
            </p>
            <p>
              L'Utente dichiara espressamente che laddove egli non sia
              pienamente titolare dei diritti sui Contenuti che procede a
              pubblicare, è stato espressamente autorizzato a disporre dei
              Contenuti dai terzi titolari di tali diritti ed è dunque
              pienamente legittimato a rilasciare l'autorizzazione ad usare i
              Contenuti con l'ampiezza prevista nelle presenti Condizioni.
            </p>
            <p>
              L'Utente si impegna a non pubblicare qualsiasi Contenuto se tale
              Contenuto e/o il suo utilizzo:
            </p>
            <ul>
              <li>
                è falso, osceno, pornografico, diffamatorio, od include
                informazioni razziste, violente, offensive, moleste, od istiga a
                commettere reati o è comunque illegale in Italia o nel luogo in
                cui l'Utente usa i Servizi o la Piattaforma od in base ad altre
                leggi comunque applicabili;
              </li>
              <li>
                viola le norme in materia di trattamento dei dati personali o in
                materia di tutela del segreto industriale e delle informazioni
                riservate;
              </li>
              <li>
                presuppone il possesso in capo all'Utente di autorizzazioni,
                diritti o requisiti richiesti dalla legge di cui questi non
                dispone;
              </li>
              <li>
                viola brevetti, marchi, diritti d'autore od altro diritto di
                terzi;
              </li>
              <li>
                contiene virus informatici o qualsiasi programma o software
                progettato per interrompere, distruggere, danneggiare od anche
                solo limitare le funzionalità di qualsiasi software, hardware od
                apparato di rete o dei Servizi o della Piattaforma stessi;
              </li>
              <li>
                sia in qualunque modo dannoso per Ugo, per gli Utenti o per i
                terzi.
              </li>
            </ul>
            <p>
              L'Utente garantisce la buona qualità dei Contenuti che procede a
              pubblicare e l'adeguatezza degli stessi all'uso al quale sono
              destinati.
            </p>
            <p>
              <strong>11. Modifiche</strong>
            </p>
            <p>
              UGO PNP si riserva il diritto, a sua sola discrezione, di
              modificare la Piattaforma o i Servizi, ovvero di modificare queste
              CU, in qualsiasi momento e con preavviso non inferiore a 15
              giorni. In caso di modifiche alle CU, esse saranno pubblicate
              sulla Piattaforma e sarà aggiornata la “Data ultimo aggiornamento”
              in calce alle CU. Continuando ad accedere o ad utilizzare la
              Piattaforma o i Servizi dal momento dell’avvenuto preavviso di
              modifica, l’Utente accetta tali condizioni così come modificate.
              Qualora ritenesse non accettabili tali modifiche, l’unica
              possibilità sarà quella di cessare l’utilizzo della Piattaforma.
            </p>
            <p>
              <strong>
                12.Diritti di proprietà intellettuale ed industriale
              </strong>
            </p>
            <p>
              Fatte salve le disposizioni contenute sulla Informativa per la
              Privacy di Ugo, qualsiasi Contenuto trasmesso mediante la
              Piattaforma dagli Utenti, sia per posta elettronica o con altri
              mezzi, per qualsiasi motivo, sarà trattato come non confidenziale
              e non di esclusiva proprietà. Nel caso in cui gli utenti detengano
              tutti i diritti di tali comunicazioni o Contenuti, essi concedono
              esplicitamente a Ugo ed ai suoi concessionari designati una
              licenza non esclusiva, con il diritto perpetuo, e in tutto il
              mondo, di copiare, distribuire, visualizzare, eseguire,
              pubblicare, tradurre, adattare, modificare, e/o utilizzare tale
              materiale per qualsiasi scopo, indipendentemente dalla forma o
              mezzo (ora noto o attualmente non noto) in cui viene utilizzato.
            </p>
            <p>
              Si prega di non pubblicare sulla Piattaforma informazioni
              riservate o tutelate come diritti di proprietà intellettuale o
              industriale, a meno che non sia stato concordato diversamente per
              iscritto.
            </p>
            <p>
              Se un Utente della Piattaforma ritiene che i suoi diritti di
              proprietà intellettuale o industriale o altri siano stati violati
              da una pubblicazione sulla Piattaforma da parte di un altro
              Utente, il primo, entro 48 ore dalla scoperta della violazione,
              deve immediatamente inviare una comunicazione scritta a Ugo
              all’indirizzo e-mail: customercare@hellougo.com.
            </p>
            <p>Per essere efficace, la notifica deve includere:</p>
            <ul>
              <li>
                La firma fisica o elettronica della persona autorizzata ad agire
                per conto del titolare di un diritto esclusivo che è stato
                presumibilmente violato;
              </li>
              <li>
                L'identificazione del Contenuto protetto da copyright che si
                ritiene sia stato violato;
              </li>
              <li>
                Informazioni ragionevolmente sufficienti per permetterci di
                contattare la parte reclamante, tra cui indirizzo, numero di
                telefono e, se disponibile, indirizzo di posta elettronica al
                quale la parte ricorrente può essere contattata;
              </li>
              <li>
                L'identificazione del materiale che si ritiene essere oggetto di
                attività illecita e le informazioni ragionevolmente sufficienti
                per permetterci di individuare il materiale;
              </li>
              <li>
                Una dichiarazione in cui la parte lesa ritiene in buona fede che
                l'uso del materiale non è autorizzato dal titolare del
                copyright, dall'agente incaricato o dalla legge;
              </li>
              <li>
                Una dichiarazione in cui la parte lesa dichiara che le
                informazioni contenute nella notifica sono accurate e, sotto
                pena di spergiuro, che la parte reclamante è autorizzata ad
                agire per conto del titolare di un diritto esclusivo,
                presumibilmente violato.
              </li>
            </ul>
            <p>
              L'Utente accetta che al momento del ricevimento di un avviso di
              reclamo di violazione della proprietà intellettuale, può rimuovere
              immediatamente i materiali identificati dalla nostra Piattaforma
              senza alcuna responsabilità nei confronti degli Utenti o di terzi.
            </p>
            <p>
              <strong>13. Manleva</strong>
            </p>
            <p>
              L'Utente della Piattaforma si impegna a liberare, difendere,
              manlevare e tenere Ugo e le sue affiliate e consociate, e i loro
              funzionari, direttori, dipendenti e agenti indenni da e contro
              qualsiasi pretesa, responsabilità, danni, perdite e spese,
              inclusi, senza limitazione, ragionevoli onorari legali e
              contabili, derivanti da, o in qualsiasi modo correlati a:
            </p>
            <ul>
              <li>
                l'accesso o l'utilizzo della Piattaforma, dei Servizi o dei
                Contenuti da parte loro o la violazione delle presenti
                Condizioni di Utilizzo da parte degli Utenti stessi;
              </li>
              <li>
                i Contenuti elaborati, trasmessi, copiati, inviati, messi a
                disposizione dagli Utenti stessi;
              </li>
              <li>
                qualsiasi delle seguenti azioni svolte dall’Utente stesso:
                <ul>
                  <li>l'interazione con qualunque Utente;</li>
                  <li>la richiesta di un Servizio;</li>
                </ul>
              </li>
            </ul>
            <p>
              si riserva il diritto, a sua esclusiva discrezione, di organizzare
              la difesa con un difensore di propria scelta, contro tutte le
              rivendicazioni legali, cause legali o di altra natura che
              richiedono una compensazione finanziaria poste in essere da parte
              dell'Utente, senza che l'Utente possa eccepire alcunché.
            </p>
            <p>
              <strong>14. Gestione reclami</strong>
            </p>
            <p>
              Se un Utente della Piattaforma ritiene che la condotta tenuta da
              altri Utenti sia inappropriata, che i suoi diritti di proprietà
              intellettuale o industriale siano stati lesi, così come la sua
              privacy, ovvero vi siano malfunzionamenti di natura tecnica della
              Piattaforma, l'Utente stesso, entro 48 ore dalla scoperta della
              violazione, deve immediatamente inviare una comunicazione scritta
              a UGO PNP S.r.l., e-mail: customercare@hellougo.com.
            </p>
            <p>Per essere efficace, la notifica deve includere:</p>
            <p>
              - La firma fisica o elettronica della persona autorizzata ad agire
              per conto del titolare di un diritto esclusivo che è stato
              presumibilmente violato;
            </p>
            <p>
              - i dati dell’Utente che sono stati forniti al momento della
              creazione dell’Account Utente al fine di poterlo correttamente
              individuare;
            </p>
            <p>
              - una spiegazione dettagliata del malfunzionamento ovvero della
              violazione e delle ragioni per cui si ritiene che tale violazione
              sia inappropriata e/o lesiva dei diritti dell’Utente che sta
              presentando il reclamo;
            </p>
            <p>
              - i dati personali dell’Utente collegati all’Account Utente di cui
              si ritiene che abbia posto in essere la violazione e/o la condotta
              illegittima;
            </p>
            <p>
              - una dichiarazione in cui la parte lesa dichiara che le
              informazioni contenute nella notifica sono accurate e, sotto pena
              di spergiuro, che la parte reclamante è autorizzata ad agire per
              conto del titolare di un diritto esclusivo, presumibilmente
              violato.
            </p>
            <p>
              Con particolare riguardo ai reclami riguardanti questioni di
              violazione della proprietà intellettuale o industriale, l’Utente
              sarà anche tenuto a comunicare nel reclamo:
            </p>
            <p>
              - L'identificazione del Contenuto protetto da copyright o
              disciplina sui marchi registrati che si ritiene sia stato violato;
            </p>
            <p>
              - L'identificazione del materiale che si ritiene essere oggetto di
              attività illecita e le informazioni ragionevolmente sufficienti
              per permetterci di individuare il materiale;
            </p>
            <p>
              - Una dichiarazione in cui la parte lesa ritiene in buona fede che
              l'uso del materiale non è autorizzato dal titolare del copyright o
              del marchio registrato, dall'agente incaricato o dalla legge;
            </p>
            <p>
              L'Utente accetta che al momento del ricevimento di un avviso di
              reclamo di violazione della proprietà intellettuale, UGO PNP può
              rimuovere immediatamente i materiali identificati sulla
              Piattaforma senza alcuna responsabilità nei confronti degli Utenti
              o di terzi.
            </p>
            <p>
              <strong>15. Relazione tra le parti</strong>
            </p>
            <p>
              Ugo, gli Utenti e gli UGO sono parti indipendenti tra loro, ognuno
              dei quali agisce in suo nome e per i propri scopi. Le presenti
              C.U. non creano alcun vincolo di subordinazione, di agenzia,
              rappresentanza, associazione, intermediazione, partnership,
              rapporto di lavoro o di franchising tra Ugo e qualsiasi Utente.
            </p>
            <p>
              Nessuna delle clausole contenute nelle CU, ovvero nelle policy di
              Ugo, ovvero di qualsivoglia altro materiale può essere considerato
              inteso a costituire, creare, dare effetto o comunque riconoscere
              una società, un'agenzia, una joint venture ovvero una entità di
              business formale di qualsiasi tipo, ovvero a creare un rapporto
              fiduciario tra le Parti.
            </p>
            <p>
              I diritti e gli obblighi delle parti devono essere limitati a
              quelli espressamente indicati nel presente documento.
            </p>
            <p>
              Il Servizio offerto da Ugo consiste esclusivamente nel mettere in
              contatto gli Utenti interessati a fruire del servizio di
              affiancamento/accompagnamento e gli UGO disponibili a prestare
              tali servizi. Ugo non è e non agisce come un venditore, né come
              compratore, rappresentante o mediatore per conto di alcuna delle
              parti coinvolte. Ugo gestisce la Piattaforma attraverso la quale
              gli Utenti possono effettuare le transazioni ed i pagamenti, ma
              non ha alcun ruolo nella transazione tra l’Utente e l’UGO.
            </p>
            <p>
              Resta ferma la facoltà di Ugo di annullare le richieste e/o
              offerte di servizio di affiancamento/accompagnamento, ancorché già
              confermate, che ritenga possano contravvenire alle regole di cui
              alle presenti CU o altri regolamenti comunque pubblicati sulla
              Piattaforma e, allo stesso modo, di sospendere gli Account
              mediante i quali tali violazioni siano state poste in essere.
            </p>
            <p>
              <strong>16. Legge applicabile e foro competente</strong>
            </p>
            <p>
              Le presenti Condizioni di Utilizzo sono regolate e interpretate in
              conformità con le leggi della Repubblica Italiana.
            </p>
            <p>
              Tutte le controversie per quanto riguarda la validità,
              interpretazione o esecuzione delle presenti Condizioni Generali
              d'Uso saranno rimesse alla competenza esclusiva del Tribunale di
              Milano.
            </p>
            <p>
              <strong>17. Condizioni aggiuntive</strong>
            </p>
            <p>
              La Società non è responsabile per i rapporti creatisi tra gli
              Utenti.
            </p>
            <p>
              Le rubriche delle clausole delle Condizioni d'uso hanno funzione
              descrittiva e non ne influenzano l’interpretazione;
            </p>
            <p>
              L'Utente accetta che i diritti e gli obblighi contenuti nelle
              presenti Condizioni di Utilizzo, nonché gli eventuali altri
              documenti che sono incorporati in esse per mezzo di riferimento
              possono essere liberamente e legittimamente trasferiti da Ugo a
              terze parti nel caso di fusione o acquisizione, o di altro evento;
            </p>
            <p>
              In ogni caso, tramite la Piattaforma, gli Utenti si impegnano a
              rispettare tutte le leggi nazionali e internazionali e tutti i
              regolamenti attualmente in vigore per l'utilizzo dei Servizi di
              Ugo in relazione alle attività svolte sul Piattaforma dagli Utenti
              stessi. Nessuna deroga alle presenti Condizioni di Utilizzo sarà
              concessa, senza l'autorizzazione scritta ottenuta ed un nuovo
              contratto firmato tra Ugo e l'Utente;
            </p>
            <p>
              Tutte le comunicazioni all'attenzione degli Utenti saranno inviate
              all'indirizzo e-mail fornito al momento della registrazione sulla
              Piattaforma. I messaggi e le notifiche sono considerate ricevute
              24 ore dopo l'invio dell'e-mail, a meno che non sia stata ricevuta
              una notifica che indichi che l'indirizzo e-mail non sia valido o
              non funzionante.
            </p>
            <p>
              <a id="_heading=h.30j0zll"></a>Ultimo aggiornamento: 13/03/2024
            </p>
          </StyledGeneratedHtmlContainer>
        </Container>
      </section>
    </div>
  );
};

export default TermsAndConditions;
